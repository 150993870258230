import React, { useState } from "react";
import catAudio from "./assets/cat.mp3";
import dogAudio from "./assets/dog.mp3";
import fishAudio from "./assets/fish.mp3";
import frogAudio from "./assets/frog.mp3";
import hippoAudio from "./assets/hippo.mp3";
import horseAudio from "./assets/horse.mp3";
import spiderAudio from "./assets/spider.mp3";
import wormAudio from "./assets/worm.mp3";
import AudioPlayer from "./AudioPlayer";

// ReactComponents

export default function App() {
  let questions = [];
  for (let i = 0; i < 15; i++) {
    questions.push(createQuestion(i));
  }

  function getAudio(param) {
    switch (param) {
      case "cat":
        return catAudio;
      case "dog":
        return dogAudio;
      case "fish":
        return fishAudio;
      case "frog":
        return frogAudio;
      case "hippo":
        return hippoAudio;
      case "horse":
        return horseAudio;
      case "spider":
        return spiderAudio;
      case "worm":
        return wormAudio;
      default:
        return catAudio;
    }
  }

  function createQuestion(i) {
    // var array = ['fa fa-ambulance', 'fa fa-car', 'fa fa-rocket', 'fa fa-thumbs-o-up', 'fa fa-video-camera', 'fa fa-ticket',	'fa fa-snowflake-o', 'fa fa-motorcycle', 'fa fa-child', 'fa fa-ban'];
    var array = [
      "fa-solid fa-hippo",
      "fa-solid fa-fish",
      "fa-solid fa-worm",
      "fa-solid fa-spider",
      "fa-solid fa-horse",
      "fa-solid fa-frog",
      "fa-solid fa-dog",
      "fa-solid fa-cat",
    ];

    array = arrayShuffle(array);
    let end;
    if (i <= 4) end = 2;
    else if (i <= 9) end = 3;
    else end = 4;

    // Question
    const q = array.slice(0, end);
    //Random 5 Items for creating the answers
    const ansSlice = array.slice(0, end + 2);
    let pos = Math.floor(Math.random() * ansSlice.length);
    const selectedAnimal = ansSlice[pos];

    const answerIsContained = q.some((element) => {
      if (element === selectedAnimal) return true;
      return false;
    });

    console.log(
      "ansSlice: ",
      ansSlice,
      "selectedAnimal: ",
      selectedAnimal,
      "pos: ",
      pos,
      "answerIsContained: ",
      answerIsContained
    );

    let ao = [];
    ao.push({
      answerText: "Όχι",
      isCorrect: !answerIsContained,
    });
    ao.push({
      answerText: "Ναι",
      isCorrect: answerIsContained,
    });

    return {
      questionText: q,
      answerOptions: ao,
      audio: selectedAnimal.replace("fa-solid fa-", ""),
    };
  }

  function arrayShuffle(array) {
    for (let index = array.length - 1; index > 0; index--) {
      const newIndex = Math.floor(Math.random() * (index + 1));
      [array[index], array[newIndex]] = [array[newIndex], array[index]];
    }
    return array;
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleAnswerOptionClick = (isCorrect) => {
    document.getElementById("question-text").style.display = "flex";
    document.getElementById("answer-section").style.display = "none";

    setVisible(false);

    if (currentQuestion < questions.length - 1) {
      setTimeout(function () {
        document.getElementById("question-text").style.display = "none";
        document.getElementById("answer-section").style.display = "flex";
        setVisible(true);
      }, 2000);
    }

    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  if (currentQuestion === 0) {
    setTimeout(function () {
      document.getElementById("question-text").style.display = "none";
      document.getElementById("answer-section").style.display = "flex";
      setVisible(true);
    }, 2000);
  }

  return (
    <div className="app">
      {showScore ? (
        <div className="score-section">
          Απαντήσατε σωστά στις {score} από τις {currentQuestion + 1} ερωτήσεις
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Ερώτηση {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div id="question-text">
              {questions[currentQuestion].questionText.map((qt) => (
                <i className={qt} style={{ fontSize: "70px" }}></i>
              ))}
            </div>
          </div>
          <div id="answer-section" style={{ display: "none" }}>
            {questions[currentQuestion].answerOptions.map((answerOption) => (
              <button
                onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}
              >
                {answerOption.answerText}
              </button>
            ))}
            {visible && (
              <AudioPlayer
                key={currentQuestion}
                audio={getAudio(questions[currentQuestion].audio)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
